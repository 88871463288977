@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Orbitron:wght@400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Vidaloka&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-body;
  }

  html {
    @apply bg-background;
  }
}

body {
}
@layer components {
  .container {
    @apply max-w-[1700px] w-[90%] mx-[auto];
  }
  .container-2 {
    @apply max-w-[1700px] w-[90%] sm:w-[80%] mx-[auto];
  }
  .roadmap-circle {
    box-shadow: 0 0px 15px 30px #2d3044;
  }

  .gradient-bg-welcome {
    @apply absolute inset-0 z-[-5] bg-gradient-bg-welcome blur-[100px] rounded-bl-[50%] rounded-br-[50%];
  }

  .performance-inhancer {
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
  }
}

.black-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 101 !important;
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
}
.black-screen.show {
  opacity: 1;
  pointer-events: visible;
}

.swiper-slide-shadow-right,
.swiper-slide-shadow-left {
  box-shadow: none !important;
  background-image: none !important;
}
